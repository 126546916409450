const generateExcelBlob = (blob: Blob) : Blob => {

    const blobFormat = new Blob([blob], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

    return blobFormat
}

const dowloadExcel = (blob : Blob, filename : string) : void => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename; // Nome do arquivo a ser baixado
    link.click();
}

export {generateExcelBlob, dowloadExcel}